import { graphql, PageProps, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Box from "../components/Box/Box";
import Layout from "../components/Layout/Layout";
import Seo from "../components/Seo";
import { Paragraph, Subtitle, Title } from "../components/Styled/Text";
import { get } from "../constants/theme";
import { clampBuilder } from "../helpers/sizeCalc";
import useWindowSize from "../hooks/useWindowSize";
import { NotFoundPageQuery } from "../types/pages/404";
import GridSection from "../components/GridSection";

interface Props extends PageProps {
  data: NotFoundPageQuery;
}


const NotFoundPage = ({ data, location }: Props) => {
  const { height } = useWindowSize();
  return (



    <Box
      as="main"
      sx={{
        minHeight: height,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >


      <GridSection
              firstChildrenType="imageBox"
              secondChildrenType="textBox"
            >
                            <>
              <Box
          as={GatsbyImage}
          sx={{
            width: "100%",

          }}
          alt={data.translationsJson.pages.notfoundpage.header.imageAlt}
          image={
            data.translationsJson.pages.notfoundpage.header.image
              .childImageSharp.gatsbyImageData
          }
        />
              </>
              <>
              {data.translationsJson.pages.notfoundpage.header.title.map(
                (t, i) => (
                  <Title
                    sx={{ textAlign: ["center", "center", "center", "center"] }}
                    key={i}
                    variant="paragraph"
                  >
                    {t}
                  </Title>
                )
              )}
                <Paragraph
                sx={{
                  textAlign: ["center", "center", "center", "center"],

                  alignSelf: "center",
                  width: "100%",
                }}
              >
                {data.translationsJson.pages.notfoundpage.header.description}
                
              </Paragraph>
              <Paragraph
                sx={{
                  textAlign: ["center", "center", "center", "center"],

                  alignSelf: "center",
                  width: "100%",
                }} >
              <Box
                sx={{
                  display: "inline",
                  cursor: "pointer",
                  textAlign: ["center", "center", "center", "center"],
                  color: get("palette.text.dark_gold"),
                }}
                as="a"
                href="/"
              >
                {data.translationsJson.pages.notfoundpage.header.urltext}
              </Box>
              </Paragraph>
              </>

            </GridSection>
    </Box>
  );
};

export default NotFoundPage;

export const notfoundpageQuery = graphql`
  query NotFoundPageQuery {
    translationsJson {
      pages {
        notfoundpage {
          header {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
            imageAlt
            urltext
          }
        }
      }
    }
    site {
      siteMetadata {
        address
        addressLink
        email
        phone
      }
    }
  }
`;
